import { createRouter, createWebHistory } from 'vue-router';
import homePage from './views/homePage.vue';
import chooseProducts from './views/chooseProducts.vue';
import quoteForm from './components/quoteForm.vue';
import AppLoginRegister from './components/AppLoginRegister.vue';
import customerPortalMainPage from './views/customerPortalMainPage.vue';
import InsuranceProductPage from './views/InsuranceProductPage.vue';
import AboutUs from './components/AboutUs.vue';
import CustomerServicePage from './views/customerServicePage.vue';
import citizensFormUpload from './views/citizensFormUpload.vue';
import policySearch from './views/policySearch.vue';
import MainDashboard from './superadminviews/MainDashboard.vue';
import ManageUsers from './superadminviews/ManageUsers.vue';
import SystemSettings from './superadminviews/SystemSettings.vue';
import CarrierManagement from './superadminviews/CarrierManagement.vue';
import Communications from './superadminviews/Communications.vue';
import createNewList from './superadminviews/communications/createNewList.vue';
import emailTemplate from './superadminviews/communications/emailTemplate.vue';
import sendEmailComponent from './superadminviews/communications/sendEmailComponent.vue';
import options from './superadminviews/accordForms/options.vue';
import recordOfSentSignatures from './superadminviews/accordForms/recordOfSentSignatures.vue';
import UnderwritingDashboard from './superadminviews/underwriting/UnderwritingDashboard.vue';
import Analytics from './superadminviews/Analytics.vue';
import OAuthCallback from './views/OAuthCallback.vue';
import RequestProofOfInsurance from './components/customerservice/requestProofOfInsurance.vue';
import makeAClaimMakeAPayment from './views/makeAClaimMakeAPayment.vue';
import UnauthorizedAccessModal from './superadminviews/components/UnauthorizedAccessModal.vue';
import store from './store/index.js';  // Assuming the store setup is correct and includes necessary getters

const routes = [
  { path: '/:pathMatch(.*)*', component: homePage, name: 'notFound' },
  { path: '/', component: homePage, name: 'home' },
  { path: '/chooseproducts', component: chooseProducts, name: 'chooseProducts' },
  { path: '/quotes/:id', component: quoteForm, props: true, name: 'quoteForm' },
  { path: '/login', component: AppLoginRegister, name: 'login' },
  { path: '/aboutus', component: AboutUs, name: 'aboutUs' },
  { path: '/dashboard', component: customerPortalMainPage, name: 'customerPortalMainPage' },
  { path: '/products/:productType', component: InsuranceProductPage, name: 'InsuranceProductPage' },
  { path: '/customerservice', component: CustomerServicePage, name: 'customerServicePage' },
  { path: '/customerservice/:formType', component: citizensFormUpload, name: 'citizensFormUpload' },
  { path: '/customerservice/carrier-directory/:carrierDirectoryType', component: makeAClaimMakeAPayment, name: 'makeAClaimMakeAPayment' },
  { path: '/policy-search', component: policySearch, name: 'policySearch' },
  { path: '/oauth/callback', component: OAuthCallback, name: 'oauthCallback' },
  { path: '/request-proof-of-insurance', component: RequestProofOfInsurance, name: 'requestProofOfInsurance' },
  { path: '/backend/dashboard', component: MainDashboard, meta: { requiresAuth: true, roles: ['superadmin', 'csr', 'underwriter', 'agent'], hideChatwoot: true }, name: 'MainDashboard' },
  { path: '/backend/manage-users', component: ManageUsers, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'manageUsers' },
  { path: '/backend/system-settings', component: SystemSettings, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'systemSettings' },
  { path: '/backend/communications', component: Communications, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'communications' },
  { path: '/backend/create-new-list', component: createNewList, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'createNewList' },
  { path: '/backend/create-template', component: emailTemplate, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'createTemplate' },
  { path: '/backend/send-email', component: sendEmailComponent, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'sendEmail' },
  { path: '/backend/analytics', component: Analytics, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'analytics' },
  { path: '/backend/carrier-management', component: CarrierManagement, meta: { requiresAuth: true, roles: ['superadmin'], hideChatwoot: true }, name: 'carrierManagement' },
  { path: '/backend/accord-form-submission', component: options, meta: { requiresAuth: true, roles: ['superadmin', 'csr'], hideChatwoot: true }, name: 'accordFormSubmission' },
  { path: '/backend/accord-form-log', component: recordOfSentSignatures, meta: { requiresAuth: true, roles: ['superadmin', 'csr'], hideChatwoot: true }, name: 'accordFormLog' },
  { path: '/backend/underwriting', component: UnderwritingDashboard, meta: { requiresAuth: true, roles: ['superadmin', 'underwriter', 'agent', 'csr'], hideChatwoot: true }, name: 'underwritingDashboard' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['authToken'];
  const userRole = store.getters['userRole'];

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ path: '/login' });
    } else if (!to.matched.some(record => record.meta.roles.includes(userRole))) {
      next({ name: 'UnauthorizedAccessModal' }); // Assuming this is how you handle unauthorized access
    } else {
      next();
    }
  } else {
    next();
  }

  // Control Chatwoot widget visibility
  if (to.matched.some(record => record.meta.hideChatwoot)) {
    window.hideChatwootWidget();
  } else {
    window.showChatwootWidget();
  }
});

export default router;
