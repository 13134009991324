<template>
  <footer class="mt-20 custom-section">
    <div class="grid grid-cols-6 z-10 absolute">
      <div class="grid grid-cols-1 bg-[#192339]">
        <div class="hidden md:block">
          <img
            class="ml-10 xl:mt-5 mt-9 w-full"
            src="@/assets/images/insurance_express-main-logo-white.svg"
          />
        </div>
      </div>
    </div>
    <div class="bg-[#192339]">
      <div class="md:hidden">
        <img
          class="px-10 md:pt-5 pt-10 max-w-[380px] w-full mx-auto"
          src="@/assets/images/insurance_express-main-logo-white.svg"
        />
      </div>
    </div>
    <!--Facebook and More-->
    <div class="text-center bg-[#192339] text-white">
      <div class="p-6">
        <div
          class="flex justify-center gap-4 xs:mx-[5%] sm:mx-[10%] md:mx-[15%] lg:mx-[25%] xl:mx-[35%]"
        >
          <!-- Facebook Link -->
          <a href="https://www.facebook.com/Insuranceexpresscom" class="group">
            <div
              class="rounded-full border-2 border-white p-2 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-f"
                class="w-6 h-6 text-white mx-auto group-hover:text-opacity-80"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </div>
          </a>
          <!-- Google Link -->
          <a href="https://maps.app.goo.gl/9Mgrx4RTiom6z29T7" class="group">
            <div
              class="rounded-full border-2 border-white p-2 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="google"
                class="w-6 h-6 text-white mx-auto group-hover:text-opacity-80"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 488 512"
              >
                <path
                  fill="currentColor"
                  d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 114.4 23.2 149.3 61.7l-60.4 58c-20.6-19.7-45.4-35.8-88.9-35.8-75.9 0-137.7 63.5-137.7 142.3 0 78.8 61.9 142.3 137.7 142.3 86.2 0 115.8-61.1 120.7-92.8H248v-74.8h236.8c2.2 12.5 3.2 24.9 3.2 38.8z"
                ></path>
              </svg>
            </div>
          </a>

          <!-- LinkedIn Link -->
          <a
            href="https://www.indeed.com/cmp/Insurance-Express-&-Member-Companies?from=mobviewjob&tk=1goolvm4fk634800&fromjk=d7b75534091aa872&attributionid=mobvjcmp"
            class="group"
          >
            <div
              class="rounded-full border-2 border-white p-2 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin-in"
                class="w-6 h-6 text-white mx-auto group-hover:text-opacity-80"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!--Menu Items Section-->
    <div class="bg-[#192339]">
      <div
        class="flex flex-col space-y-6 md:grid md:grid-cols-4 md:space-y-0 mb-8"
      >
        <div v-for="(menu, index) in menus" :key="index" class="text-center">
          <!-- Button for mobile -->
          <button
            v-if="screenWidth <= 768"
            class="uppercase font-bold font-merriweather mb-2.5 text-white focus:outline-none"
            @click="menu.show = !menu.show"
          >
            {{ menu.title }}
            <i
              :class="menu.show ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
            ></i>
          </button>
          <!-- Static title for desktop -->
          <div
            v-else
            class="uppercase font-bold font-merriweather mb-2.5 text-center text-white"
          >
            {{ menu.title }}
          </div>
          <transition name="slide-fade">
            <!-- Show menus if on desktop or if menu.show is true -->
            <ul
              v-show="screenWidth > 768 || menu.show"
              class="list-none text-sm font-roboto md:bg-[#192339] bg-white text-center mb-0 grid md:grid-cols-2 md:hover:text-red-600"
              :class="
                ['About', 'Resources'].includes(menu.title)
                  ? 'grid-cols-1 md:grid-cols-1'
                  : 'grid-cols-2 md:grid-cols-2'
              "
            >
              <template v-if="menu.title === 'About'">
                <li class="mobile-view">
                  <a
                    :href="menu.items[0].link"
                    class="md:text-white text-[#192339] text-lg md:text-sm hover:text-red-500"
                    >{{ menu.items[0].name }}</a
                  >
                </li>
                <li
                  v-for="(item, i) in menu.items.slice(1)"
                  :key="i"
                  class="mobile-view my-2"
                >
                  <a
                    :href="item.link"
                    class="md:text-white text-[#192339] text-lg md:text-sm hover:text-red-500"
                    >{{ item.name }}</a
                  >
                </li>
              </template>
              <template v-else>
                <li
                  v-for="(item, i) in menu.items"
                  :key="i"
                  class="mobile-view my-2"
                >
                  <a
                    :href="item.link"
                    class="md:text-white text-[#192339] text-lg md:text-sm hover:text-red-500"
                    >{{ item.name }}</a
                  >
                </li>
              </template>
            </ul>
          </transition>
        </div>
      </div>
      <div class="text-center p-4 text-xs font-merriweather bg-[#161F32]">
        <a class="text-white"
          >© 2024 Copyright: Insurance Express and Member Companies</a
        >
      </div>
    </div>
  </footer>
</template>

<style scoped>
.slide-fade-enter-active {
  transition:
    height 1.2s ease-in-out,
    opacity 0.1s ease-in-out;
}

.slide-fade-leave-active {
  transition:
    height 1.2s ease-in-out,
    opacity 0.1s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.slide-fade-enter-to,
.slide-fade-leave {
  opacity: 1;
  height: auto;
}
.custom-section {
  margin: 0; /* Remove the default margin */
}
.mobile-view {
  font-size: 1.25rem; /* Increase font size */
}

/* Color change on click */
a:active {
  color: red;
}

@media screen and (max-width: 768px) {
  ul {
    grid-template-columns: repeat(
      1,
      1fr
    ) !important; /* Single column layout on mobile */
  }
}
</style>

<script>
export default {
  data() {
    return {
      screenWidth: window.innerWidth,
      menus: [
        {
          title: 'About',
          items: [
            { name: 'About Us', link: '/aboutus' },
            { name: 'Customer Service', link: '/customerservice' },
            {
              name: 'Claims',
              link: '/customerservice/carrier-directory/make-claim',
            },
            {
              name: 'Make a Payment',
              link: '/customerservice/carrier-directory/make-payment',
            },
            {
              name: 'Careers',
              link: 'https://www.indeed.com/cmp/Insurance-Express-&-Member-Companies?from=mobviewjob&tk=1goolvm4fk634800&fromjk=d7b75534091aa872&attributionid=mobvjcmp',
            },
          ],
          show: false,
        },
        {
          title: 'Products',
          items: [
            { name: 'Home Insurance', link: '/products/homeinsurance' },
            { name: 'Auto Insurance', link: '/products/autoinsurance' },
            { name: 'Flood Insurance', link: '/products/floodinsurance' },
            { name: 'Medicare', link: '/products/medicare' },
            { name: 'Life Insurance', link: '/products/lifeinsurance' },
            // { name: 'Pet Insurance', link: '/products/petinsurance' },
            { name: 'Umbrella Insurance', link: '/products/umbrellainsurance' },
            { name: 'Business Insurance', link: '/products/businessinsurance' },
          ],
          show: false,
        },
        {
          title: 'Resources',
          items: [
            {
              name: 'Join the Team',
              link: 'https://www.indeed.com/cmp/Insurance-Express-&-Member-Companies?from=mobviewjob&tk=1goolvm4fk634800&fromjk=d7b75534091aa872&attributionid=mobvjcmp',
            },
          ],
          show: false,
        },
        {
          title: 'Member Companies',
          items: [
            { name: 'Rocket Flood', link: 'https://rocketflood.com/' },
            { name: 'Rocket MGA', link: 'https://rocketmga.com/' },
            { name: 'Str8line Group', link: 'http://www.str8linegroup.com/' },
            {
              name: 'Str8line Enterprises',
              link: 'http://www.str8linegroup.com/',
            },
            { name: 'QuoteSlash', link: 'https://quoteslash.com/' },
            { name: 'Protego VIP', link: 'https://protegovip.com/' },
            { name: 'TheLeadExchange', link: 'https://theleadexchange.com/' },
            { name: 'NuBuild', link: 'https://nubuildinsurance.com/' },
            { name: 'The IE Pipeline', link: 'https://iepipeline.com/' },
            {
              name: 'Graham Newton & McMahon Inc.',
              link: 'https://gnminsurance.com/',
            },
          ],
          show: false,
        },
      ],
    };
  },
  created() {
    window.addEventListener('resize', this.updateScreenWidth);
    this.updateScreenWidth();
  },
  methods: {
    updateScreenWidth() {
      if (window.visualViewport) {
        this.screenWidth =
          window.visualViewport.width * window.visualViewport.scale;
      } else {
        this.screenWidth = window.innerWidth;
      }
      console.log('Adjusted screenWidth to:', this.screenWidth);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
};
</script>
